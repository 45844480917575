<template>
  <div>

    <div class="row justify-content-end form-rounded-inputs ">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="input-group">
            <select name="" class="custom-select" v-model="reviewsPagination.qualification"
              @change="fnFirstPagination()">
              <option :value="null" v-text="$t('general.form.all')"> </option>
              <option :value="true" v-text="$t('dashboard.questions.positive')"> </option>
              <option :value="false" v-text="$t('dashboard.questions.negative')"> </option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-success">
                <i class="fa fa-comment"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="(reviewsList.length > 0) && !loading.data">
      <table class="table table-bordered table-striped animated fadeIn ">
        <transition-group tag="tbody" class="list-container" :duration="{ leave: 1000 }"
          enter-active-class="animated bounceIn" leave-active-class="animated fadeOutRight">
          <tr v-for=" (review) in reviewsList " :key="review.id" class="animated fadeIn">
            <td>
              <div class="row justify-content-betwen ">
                <div class="col-9">
                  <p class="m-0 text-truncate" v-if="productId == null && review.product != null">
                    <router-link :to=" `/dashboard/products/${review.product.id}` " class="text-muted"
                      v-if="review.product">
                      <small
                        v-text=" review.product ? (review.product.name != 'notFound' ? review.product.name : $t('messages.productNotFound') ) : ( $t('tables.loading') )"></small>
                    </router-link>
                  </p>
                </div>
                <!-- <div class="col-3 d-none">
                  <p class="text-right m-0">
                    <button class="btn btn-link btn-xs p-0 " @click="review.showDeleteForm = !review.showDeleteForm"
                      v-text=" review.showDeleteForm ?  'Cancelar' : 'Denunciar' ">
                    </button>
                  </p>
                </div> -->
              </div>
              <p class="m-0 text-success" v-html=" fnGetStars( review.review ) "> </p>
              <p class="m-0">
                <span v-text=" review.comment "></span>
                <small class="text-muted" v-text=" ' - ' + fnGlobalGetTime( review.updated_at ) "></small>
              </p>
              <div class="animated fadeIn bg-light p-3" v-show="review.showDeleteForm">
                <div class="form-group mb-2">
                  <label for=""> Porque deseas denunciar esta pregunta? </label>
                  <select name="" id="" class="custom-select">
                  </select>
                </div>
                <p class="text-right">
                  <button class="btn btn-info btn-sm">
                    Enviar
                  </button>
                </p>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>

      <div v-if=" reviewsPagination.total > 0 && ( (reviewsPagination.total / reviewsPagination.limit) > 1 ) ">
        <b-pagination v-model="reviewsPagination.page" :total-rows="reviewsPagination.total"
          :per-page="reviewsPagination.limit" @input="fnApiGetReviews" align="fill"
          :first-text="$t('general.button.first')" :prev-text="$t('general.button.previous')"
          :next-text="$t('general.button.next')" :last-text="$t('general.button.last')" class="d-none d-md-flex">
        </b-pagination>
        <b-pagination v-model="reviewsPagination.page" :total-rows="reviewsPagination.total"
          :per-page="reviewsPagination.limit" @input="fnApiGetReviews" align="fill" class=" d-md-none">
        </b-pagination>
      </div>

    </div>
    <no-data v-if=" reviewsList.length == 0 || loading.data " :isLoading="loading.data"
      :dataMessage="$t('noDataMessages.reviewsList')" :showBtn="false">
    </no-data>

  </div>
</template>

<script>
  import {
    mapActions,
    mapMutations,
    mapState
  } from 'vuex';

  export default {
    props: {
      productId: {
        type: String,
      }
    },
    data() {
      return {}
    },
    watch: {
      async reviewsList() {
        let tempList = [];
        this.reviewsList.map(review => {
          tempList.push(review.product_id);
        })
        await this.fnApiGetProductsInformation(tempList);
        this.fnSetProducts();
      },
      productsList() {
        this.fnSetProducts();
      }
    },
    computed: {
      ...mapState('productReviews', ['loading', 'reviewsList', 'reviewsPagination']),
      ...mapState('EcartProducts', ['productsList']),
    },
    methods: {
      ...mapMutations('productReviews', ['setProductId', 'resetPagination']),
      ...mapActions('productReviews', ['fnFirstPagination', 'fnResetPagination', 'fnApiGetReviews',
        'fnApiGetCountReviews'
      ]),
      ...mapActions('EcartProducts', ['fnApiGetProductsInformation']),

      fnSetProducts() {
        this.reviewsList.map(review => {
          if (review.product == null) {
            let tempProduct = this.productsList.find(product => (product.id == review.product_id));
            if (tempProduct != undefined) {
              review.product = tempProduct;
            }
          }
        });
      }
    },
    mounted() {
      if (this.productId) {
        this.fnResetPagination(this.productId);
      } else {
        this.fnResetPagination();
      }
    },
  }
</script>